<template>
  <div class="info_wrapper">
    <van-form>
      <div class="info_header" ref="title">
        <div class="header_msg">填写预约信息</div>
      </div>
      <div class="policy_holder_info">
        <div class="policy_holder_info_header">
          <span class="policy_holder_info_tag">1</span>
          <div class="policy_holder_info_msg">患者信息</div>
        </div>
        <van-field
          class="my_insured_field"
          v-model="policyHolderInfo.personalName"
          name="personalName"
          label="姓名"
          placeholder="请输入患者姓名"
          label-class="my_insured_label"
          required
        />
        <van-field
          class="my_insured_field"
          v-model="policyHolderInfo.personalIdCardNum"
          name="personalIdCardNum"
          label="证件号码"
          placeholder="请输入患者证件号码"
          label-class="my_insured_label"
          required
        />
        <van-field
          class="my_insured_field"
          v-model="policyHolderInfo.personalPhoneNum"
          name="personalPhoneNum"
          label="手机号码"
          placeholder="请输入患者手机号码"
          label-class="my_insured_label"
          required
        />

        <van-field
          class="my_field"
          readonly
          clickable
          name="area"
          :value="provinceValue"
          label-class="my_field_label"
          label="服务地址(省)"
          required
          placeholder="请选择省"
          @click="showAreaProvince = true"
        />
        <van-popup v-model="showAreaProvince" position="bottom">
          <van-picker
            title="选择省"
            show-toolbar
            :columns="provinceList"
            @confirm="onProvinceConfirm"
            @cancel="showAreaProvince = false"
          />
        </van-popup>
        <van-field
          class="my_field"
          readonly
          clickable
          name="area"
          :value="cityValue"
          label-class="my_field_label"
          label="服务地址(市)"
          required
          placeholder="请选择市"
          @click="showAreaCity = true"
        />
        <van-popup v-model="showAreaCity" position="bottom">
          <van-picker
            title="选择市"
            show-toolbar
            :columns="cityList"
            @confirm="onCityConfirm"
            @cancel="showAreaCity = false"
          />
        </van-popup>
        <van-field
          class="my_field"
          readonly
          clickable
          name="area"
          :value="countryValue"
          label-class="my_field_label"
          label="服务地址(区)"
          required
          placeholder="请选择区"
          @click="showAreaCounty = true"
        />
        <van-popup v-model="showAreaCounty" position="bottom">
          <van-picker
            title="选择区"
            show-toolbar
            :columns="countyList"
            @confirm="onCountyConfirm"
            @cancel="showAreaCounty = false"
          />
        </van-popup>
        <van-field
          class="my_insured_field"
          v-model="areaInfo.address"
          name="address"
          label="详细地址"
          placeholder="请输入详细地址"
          label-class="my_insured_label"
          required
        />
        <van-field
          class="my_field"
          readonly
          clickable
          name="area"
          :value="bookingTime"
          label-class="my_field_label"
          label="预约时间"
          required
          placeholder="请选择预约时间"
          @click="showDate = true"
        />
        <van-popup v-model="showDate" position="bottom">
          <van-datetime-picker
            type="datetime"
            @confirm="onBookingTime"
            @cancel="showDate = false"
            @change="bookingTimeChange"
            label="预约时间"
            placeholder="请选择预约时间"
            :min-date="minDate"
          />
        </van-popup>
        <div style="padding:0 16px;"><div style="color:red;font-size:14px;line-height:16px;">温馨提示：为保证服务质量，预约服务需提前48小时，我们会在48小时内完成服务派单，请您耐心等候。</div></div>
        <div style="height:7rem;width；100%;"></div>
      </div>
      <div class="form_bottom_box">
        <div class="check_box">
          <span class="link" @click="isDetail = true">《产品详情》</span>
        </div>
        <div class="bottom_handle">
          <!-- <div class="price">{{ `${insuredInfoList.length * total}元` }}</div> -->
          <div class="handle_btn" @click="handleConfirm">立即预约</div>
        </div>
      </div>
    </van-form>

    <van-popup v-model="isDetail" position="bottom" class="tip-pop-detail">
      <!-- <img src="./assets/img/a3.png" /> -->
      <img :src="productImg" alt="">
    </van-popup>
  </div>
</template>

<script>
import "./SunshineInsBooking.less";
import {
  Field,
  Form,
  Popup,
  Notify,
  Toast,
  Picker,
  DatetimePicker
} from "vant";
// import SunshineInsBookingItem from "./SunshineInsBookingItem/SunshineInsBookingItem.vue";
import { verifyCardNo, isMobile } from "../../utils/verifyUtils";
import { userBooking, getAreaList, productImg } from '../../api/api';
export default {
  name: "SunshineInsBooking",
  data() {
    return {
      // 被保人信息列表
      insuredInfoList: '',
      insuredInfo: {},
      insurances: [],
      productId: this.$route.query.productId,
      productCode: '',
      userInfo: {},
      isDetail: false,
      policyHolderInfo: {
        name: '',
        personalIdCardNum: '',
        personalPhoneNum: ''
      }, //患者信息
      showAddInsured: false,   //添加被保人弹窗
      isItemEdit: false,   //判断是否编辑状态
      provinceValue: "", // 省市区
      cityValue: "", // 省市区
      countryValue: "", // 省市区
      showArea: false,
      areaList: {
        province_list: [],
        city_list: [],
        county_list: [],
      }, // 数据格式见 Area 组件文档
      defauleCountyCode: "",
      orderInfo: {
        source: '',
        apiSource: '',
        customerNo: '',
        card_code: '',
        orderId: '',
        signType: '',
        sign: '',
        redirectUrl: ''
      },
      areaInfo: {
        provinceCode: '',
        cityCode: '',
        districtCode: '',
        address: ''
      },
      bookingTime: '',
      bookingTime2: '',
      provinceList: [],
      cityList: [],
      countyList: [],
      showAreaProvince: false,
      showAreaCounty: false,
      showAreaCity: false,
      showDate: false,
      productImg: '',
      minDate: new Date(Date.now() + (8.64e7 * 2)),
      // maxDate: new Date(Date.now())
    };
  },
  components: {
    "van-form": Form,
    "van-field": Field,
    "van-popup": Popup,
    // SunshineInsBookingItem,
    "van-picker": Picker,
    "van-datetime-picker":DatetimePicker
  },
  mounted() {
    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);

    getAreaList({ parentCode: 0 }).then(res => {
      res.data.forEach(res => {
        this.provinceList.push(res.name);
        this.areaList.province_list.push(res);
      });
      console.log(this.areaList);
    });
  },

  created() {
    const url = window.location.href;
    console.log(url);
    // const url = "http://dsins.bigtreedev.com?eyJhcGlTb3VyY2UiOiJiaWdUcmVlIiwiY2FyZF9jb2RlIjoiQ1NMTVlDUjYxV1VSVkVPVE82NEpPVEIwIiwicmVkaXJlY3RVcmwiOiJodHRwczovL21jbHViLnNpbm9zaWcuY29tL2NvdXBvbkxpc3QiLCJvcmRlcklkIjoiNDYwODUyIiwic2lnbiI6IjA4RTE2Qjk1MEQxNjcwN0EyODhDM0FGMEUxQzFDQzY2Iiwic2lnblR5cGUiOiJNRDUiLCJ0ZWxlcGhvbmUiOiIxNzg2MjY2Nzc3NCIsInNvdXJjZSI6ImJpZ1RyZWUiLCJjdXN0b21lck5vIjoiMTk2MzUwNjcifQ==";
    const params = this.convertUrlQuery(url);
    console.log(params);
    this.orderInfo = {
      source: params.source,
      apiSource: params.apiSource,
      customerNo: params.customerNo,
      card_code: params.card_code,
      orderId: params.orderId,
      signType: params.signType,
      sign: params.sign,
      redirectUrl: params.redirectUrl,
      // productId: params.productId,
      // productName: params.productName,
      // productPlanCode: params.productPlanCode,
      // productPlanName: params.productPlanName
    }
    this.policyHolderInfo.personalPhoneNum = params.telephone;
    console.log(this.policyHolderInfo);
    this.getProductImg(params.card_code)
  },

  destroyed() {
    clearTimeout(this.timer);
  },

  methods: {
    onProvinceConfirm(value) {
      const province = this.areaList.province_list.filter((res) => res.name === value)[0];
      this.areaInfo.provinceCode = province.code;
      this.provinceValue = value;
      this.cityList = []
      this.areaList.city_list = []
      getAreaList({ parentCode: province.code }).then(res => {
        res.data.forEach(res => {
          this.cityList.push(res.name);
          this.areaList.city_list.push(res);
        });
      });
      this.showAreaProvince = false;
    },
    onCityConfirm(value) {
      const city = this.areaList.city_list.filter((res) => res.name === value)[0];
      this.areaInfo.cityCode = city.code;
      this.cityValue = value;
      this.countyList = []
      this.areaList.county_list = []
      getAreaList({ parentCode: city.code }).then(res => {
        res.data.forEach(res => {
          this.countyList.push(res.name);
          this.areaList.county_list.push(res);
        });
      });
      this.showAreaCity = false;
    },
    onBookingTime(msg) {
      this.bookingTime = msg
      this.bookingTimeChange()
      this.bookingTime = this.bookingTime2
      this.showDate = false
    },
    bookingTimeChange(){
      var date = new Date(this.bookingTime)
      var YY = date.getFullYear();
      var MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1);
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
      var mm =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
      var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      if(Number(hh) >= 20){
        DD = (Number(DD) + 1) < 10 ? "0" + (Number(DD) + 1) : (Number(DD) + 1);
        hh = '08'
        mm = '00:'
        ss = '00'
      }else if(Number(hh) < 8){
        hh = '08'
        mm = '00:'
        ss = '00'
      }
      this.bookingTime2 = YY + '-' + MM + '-' + DD + ' ' + hh + ":" + mm + ss
    },
    onCountyConfirm(value) {
      const county = this.areaList.county_list.filter((res) => res.name === value)[0];
      this.areaInfo.districtCode = county.code;
      this.countryValue = value;
      this.showAreaCounty = false;
    },
    convertUrlQuery(url) {
      var theRequest = new Object();
      console.log('atob', atob)
      console.log('params', decodeURIComponent(url.substr(url.indexOf("?") + 1)));
      if (url.indexOf("?") != -1) {
        theRequest = JSON.parse(atob(decodeURIComponent(url.substr(url.indexOf("?") + 1))));
      }
      return theRequest;
    },
    getInsuredData(e) {
      if (!this.isItemEdit)
        this.insuredInfoList = e;
    },

    delOne() {
      this.insuredInfoList = '';
    },

    closrInsuredPopup(e) {
      console.log(e);
      if (e) this.showAddInsured = false;
      else this.insuredInfo = {}
    },

    handleWrite(item) {
      if (item) this.isItemEdit = true;
      this.showAddInsured = true;
      this.insuredInfo = item || {};
    },

    // 点击立即预约
    handleConfirm() {
      const errMsg = this.verifyFormData();
      if (errMsg) {
        // 校验患者
        Notify(errMsg);
      } else {
        // if (!this.insuredInfoList) {
        //   Notify("请添加患者信息");
        // } else {
        this.savePolicy();
        // }
      }
    },
    // 患者表单校验
    verifyFormData() {
      const {
        personalName,
        personalPhoneNum,
        personalIdCardNum
      } = this.policyHolderInfo;

      const address = this.areaInfo.address;
      // 个人预约校验规则
      let verifyRules = [];
      verifyRules = [
        {
          errMsg: "请输入患者姓名",
          value: personalName,
          type: "personalName",
        },
        {
          errMsg: "请输入患者手机号",
          value: personalPhoneNum,
          verifyMsg: "手机号格式不正确",
          type: "personalPhoneNum",
        },
        {
          errMsg: "请输入患者身份证号码",
          value: personalIdCardNum,
          verifyMsg: "身份证号码格式不正确",
          type: "personalIdCardNum",
        },
        {
          errMsg: "请输入服务地址(省)",
          value: this.provinceValue,
          verifyMsg: "请输入服务地址(省市区)",
          type: "provinceValue",
        },
        {
          errMsg: "请输入服务地址(市)",
          value: this.cityValue,
          verifyMsg: "请输入服务地址(市)",
          type: "cityValue",
        },
        {
          errMsg: "请输入服务地址(区)",
          value: this.countryValue,
          verifyMsg: "请输入服务地址(区)",
          type: "countryValue",
        },
        {
          errMsg: "请输入服务详细地址",
          value: address,
          verifyMsg: "请输入服务详细地址",
          type: "address",
        },
        {
          errMsg: "请输入预约时间",
          value: this.bookingTime,
          verifyMsg: "请输入预约时间",
          type: "bookingTime",
        },
      ];
      return this.getErrorMsg(verifyRules);
    },
    // 错误信息提示
    getErrorMsg(verifyRules) {
      let errorArray = [];
      verifyRules.map((item) => {
        if (!item.value) {
          errorArray.push(item.errMsg);
        } else if (item.verifyMsg) {
          if (item.type === "personalIdCardNum") {
            const personalIdCardNum = verifyCardNo(item.value);
            if (personalIdCardNum) {
              errorArray.push(item.verifyMsg);
            }
          }
          if (item.type === "personalPhoneNum") {
            const personalPhoneNum = isMobile(item.value);
            if (!personalPhoneNum) {
              errorArray.push(item.verifyMsg);
            }
          }
        }
      });
      if (!errorArray.length) return false;
      return errorArray[0];
    },
    savePolicy() {
      Toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner'
      })
      console.log(this.orderInfo.productName );
      const policyParams = {
        source: this.orderInfo.source,
        apiSource: this.orderInfo.apiSource,
        customerNo: this.orderInfo.customerNo,
        card_code: this.orderInfo.card_code,
        orderId: this.orderInfo.orderId,
        signType: this.orderInfo.signType,
        sign: this.orderInfo.sign,
        name: this.policyHolderInfo.personalName,
        telephone: this.policyHolderInfo.personalPhoneNum,
        idNumber: this.policyHolderInfo.personalIdCardNum,
        // productMessage: `${this.orderInfo.productId}|${this.orderInfo.productName}|${this.orderInfo.productPlanCode}|${this.orderInfo.productPlanName}`,
        // productMessage: '202102001|团体意外服务包(基础版)|202101001A|团体意外服务包(基础版)',
        address: this.areaInfo.address,
        provinceCode: this.areaInfo.provinceCode,
        cityCode: this.areaInfo.cityCode,
        districtCode: this.areaInfo.districtCode,
        redirectUrl: this.orderInfo.redirectUrl,
        bookingTime: this.bookingTime
      };
      userBooking(policyParams).then(data => {
        Toast.clear();
        console.log(data);
        if (data.code === 200) {
          Toast.success('预约成功,即将跳转回去原页面');
          setTimeout(() => {
             location.href = this.orderInfo.redirectUrl;
          }, 2500)
        }
        else {
          Toast.fail(data.message);
        }
      })
    },
    getProductImg(code) {
      // productImg({couponCode: 'CSLQ0KU1RF6T8S9HDI06NVOF'}).then(res => {
      productImg({couponCode: code}).then(res => {
        if(res.code == 200){
          this.productImg = res.data
        } else {
          this.productImg = require('../../assets/images/img404.jpg')
        }
      })
    }
  },
};
</script>
